import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import PrivacyControl from "images/icon_privacy_controls.svg"
import OperationalPrivacy from "images/icon_operational_privacy.svg"
import { Card, CardProps } from "./card"

const points: CardProps[] = [
	{
		icon: PrivacyControl,
		title: "security-page.privacy.points.1.title",
		desc: "security-page.privacy.points.1.desc",
	},
	{
		icon: OperationalPrivacy,
		title: "security-page.privacy.points.2.title",
		desc: "security-page.privacy.points.2.desc",
	},
]

export const Privacy = () => {
	const { t } = useTranslation()

	return (
		<section id="privacy" className="flex flex-col gap-16">
			<div className="flex flex-col gap-6 items-center text-center">
				<span className="tracking-wider text-gray-500 uppercase">
					{t("common.words.privacy")}
				</span>
				<p className="text-4xl tracking-tight sm:text-5xl max-w-2xl">
					{t("security-page.privacy.heading")}
				</p>
				<p className="text-base sm:text-lg md:text-xl max-w-3xl">
					{t("security-page.privacy.desc")}
				</p>
			</div>

			<div className="grid sm:grid-cols-2 gap-10">
				{points.map((point) => (
					<Card key={point.title} {...point} />
				))}
			</div>
		</section>
	)
}
