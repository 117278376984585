import React from "react"
import { Container } from "components/library"
import { graphql } from "gatsby"
import { CtaBlock } from "components/common/cta-block"
import { Hero } from "components/pages/security/hero"
import { Benefits } from "components/pages/security/benefits/benefits"
import { Security } from "components/pages/security/security/security"
import { Privacy } from "components/pages/security/privacy"
import { Reliability } from "components/pages/security/reliability"
import { Compliance } from "components/pages/security/compliance"

const TrustCenter = () => {
	return (
		<main>
			<Container className="flex flex-col gap-28 pt-12 pb-32">
				<Hero />
				<Benefits />
				<Security />
				<Privacy />
				<Reliability />
				<Compliance />
			</Container>

			<CtaBlock />
		</main>
	)
}

export default TrustCenter

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
