import React, { ComponentPropsWithoutRef } from "react"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import { mergeClasses } from "../../utils"
import { PrimaryAlternativeBtn } from "../library"

type P = ComponentPropsWithoutRef<"button">

export const LearnMoreCta = ({ className = "", ...props }: P) => {
	const { t } = useTranslation()

	return (
		<Link to="/products/overview/">
			<PrimaryAlternativeBtn
				className={mergeClasses({ classes: ["md:text-lg hover:text-white", className] })}
				tabIndex={-1}
				{...props}
			>
				{t("common.cta.learn-more")}
			</PrimaryAlternativeBtn>
		</Link>
	)
}
