import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import UptimeNAvailability from "images/icon_availability.svg"
import RealiabilityCommitment from "images/icon_reliability_commitment.svg"
import { Card, CardProps } from "./card"

const points: CardProps[] = [
	{
		icon: UptimeNAvailability,
		title: "security-page.reliability.points.1.title",
		desc: "security-page.reliability.points.1.desc",
	},
	{
		icon: RealiabilityCommitment,
		title: "security-page.reliability.points.2.title",
		desc: "security-page.reliability.points.2.desc",
	},
]

export const Reliability = () => {
	const { t } = useTranslation()

	return (
		<section id="reliability" className="text-center flex flex-col gap-16">
			<div className="flex flex-col gap-6 items-center">
				<span className="tracking-wider text-gray-500">
					{t("common.words.reliability")}
				</span>
				<p className="text-4xl tracking-tight text-gray-900 sm:text-5xl max-w-2xl">
					{t("security-page.reliability.heading")}
				</p>
				<p className="text-base sm:text-lg md:text-xl max-w-3xl">
					{t("security-page.reliability.desc")}
				</p>
			</div>

			<div className="text-left grid sm:grid-cols-2 gap-10">
				{points.map((point) => (
					<Card key={point.title} {...point} />
				))}
			</div>
		</section>
	)
}
