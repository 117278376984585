import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { CardProps, Card } from "./card"
import InternalSecurity from "images/illus_trust_internal_security.svg"
import ProductSecurity from "images/illus_trust_product_security.svg"

const internal: CardProps = {
	icon: InternalSecurity,
	heading: "security-page.security.security.internal.heading",
	content: [
		{
			heading: "security-page.security.security.internal.content.1.heading",
			paras: [
				"security-page.security.security.internal.content.1.para.1",
				"security-page.security.security.internal.content.1.para.2",
			],
		},
		{
			heading: "security-page.security.security.internal.content.2.heading",
			paras: [
				"security-page.security.security.internal.content.2.para.1",
				"security-page.security.security.internal.content.2.para.2",
			],
		},
		{
			heading: "security-page.security.security.internal.content.3.heading",
			paras: ["security-page.security.security.internal.content.3.para.1"],
		},
	],
}

const product: CardProps = {
	icon: ProductSecurity,
	heading: "security-page.security.security.product.heading",
	content: [
		{
			heading: "security-page.security.security.product.content.1.heading",
			paras: ["security-page.security.security.product.content.1.para.1"],
		},
		{
			heading: "security-page.security.security.product.content.2.heading",
			paras: ["security-page.security.security.product.content.2.para.1"],
		},
	],
}

export const Security = () => {
	const { t } = useTranslation()

	return (
		<section id="security" className="flex flex-col gap-24">
			<div className="flex flex-col gap-6 items-center text-center">
				<span className="tracking-wider text-gray-500 uppercase">
					{t("common.words.security")}
				</span>
				<p className="text-4xl tracking-tight sm:text-5xl max-w-2xl">
					{t("security-page.security.title")}
				</p>
				<p className="sm:text-lg md:text-xl max-w-3xl">
					{t("security-page.security.desc")}
				</p>
			</div>

			<Card {...internal} />
			<Card {...product} reverse />
		</section>
	)
}
