import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import SOC from "images/logo_SOC2.svg"
import ISO from "images/logo_ISO.svg"
import GDPR from "images/logo_GDPR.svg"
import { Card, CardProps } from "./card"
import { getSoc2Link } from "utils/general/links"

const points: CardProps[] = [
	{
		icon: SOC,
		title: "security-page.compliance.points.1.title",
		desc: "security-page.compliance.points.1.desc",
	},
	{
		icon: ISO,
		title: "security-page.compliance.points.2.title",
		desc: "security-page.compliance.points.2.desc",
	},
	{
		icon: GDPR,
		title: "security-page.compliance.points.3.title",
		desc: "security-page.compliance.points.3.desc",
	},
]

export const Compliance = () => {
	const { t } = useTranslation()

	return (
		<section id="compliance" className="text-center flex flex-col gap-16">
			<div className="flex flex-col gap-6 items-center">
				<span className="tracking-wider text-gray-500 uppercase">
					{t("common.words.compliance")}
				</span>
				<p className="text-4xl tracking-tight sm:text-5xl max-w-2xl">
					{t("security-page.compliance.heading")}
				</p>
				<p className="sm:text-lg md:text-xl max-w-3xl">
					{t("security-page.compliance.desc")}
				</p>
			</div>

			<div className="sm:text-left grid sm:grid-cols-3 gap-12">
				{points.map((point) => (
					<Card key={point.title} {...point} />
				))}
			</div>

			<a
				className="text-left sm:text-lg"
				href={getSoc2Link(process.env.GATSBY_LANGUAGE_SELECTED)}
				target="_blank"
				rel="noopener noreferrer"
			>
				<Trans
					i18nKey="security-page.footer-note"
					components={{
						bold: <span className="text-theme-blue font-semibold" />,
					}}
				/>
			</a>
		</section>
	)
}
