import React from 'react'
import { ArrowRight } from 'react-feather'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { TranslationKey } from 'types/translation'

type P = {
  title: TranslationKey
  desc: TranslationKey
  icon: string
  link: string
}

export type CardProps = P

export const Card = ({ title, desc, icon, link }: P) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center sm:items-start gap-4">
      <img alt="Security" className="w-16" src={icon} />

      <p className="text-2xl">{t(title) as string}</p>
      <p>{t(desc) as string}</p>

      <a href={link} className="font-bold mt-auto flex items-center gap-2">
        <span>{t('common.cta.learn-more')}</span>
        <ArrowRight size={16} />
      </a>
    </div>
  )
}
