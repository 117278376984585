import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TranslationKey } from 'types/translation';

type ContentBlock = {
    heading: TranslationKey
    paras: TranslationKey[]
}

type P = {
    icon: string
    heading: TranslationKey
    content: ContentBlock[]
    reverse?: boolean
};

export type CardProps = P

export const Card = ({ content, heading, icon, reverse = false }: P) => {
    const { t } = useTranslation()

    return (
        <div className={`flex flex-col ${reverse ? "sm:flex-row-reverse" : "sm:flex-row"} items-center gap-16`}>
            <img
                src={icon}
                className="sm:w-[35%] shrink-0"
                alt={t(heading) as string}
            />

            <div className="w-full prose-sm">
                <h2 className="text-4xl tracking-tight sm:text-5xl">{t(heading) as string}</h2>

                {content.map(c => {
                    return (
                        <div key={c.heading}>
                            <h3>{t(c.heading) as string}</h3>
                            {c.paras.map(p => {
                                return <p key={p}>{t(p) as string}</p>
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
};
