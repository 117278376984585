import React, { ComponentPropsWithoutRef } from "react"
import { Container, PrimaryBtn } from '../library'
import { mergeClasses } from '../../utils'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { LearnMoreCta } from "./learn-more-cta"

type P = ComponentPropsWithoutRef<'section'> & {
    ignoreClasses?: string
}

export const CtaBlock = ({ className = '', ignoreClasses = '', ...props }: P) => {
    const { t } = useTranslation()

    return (
        <section
            className={mergeClasses({
                classes: ["bg-no-repeat bg-cta-bg bg-cover w-full", className],
                ignore: ignoreClasses
            })}
            {...props}
        >
            <Container className='flex flex-col pb-20 pt-40 text-white gap-6'>
                <h2 className="text-4xl tracking-tight font-medium sm:text-5xl max-w-3xl">
                    {t('cta-block.title')}
                </h2>

                <p className="text-lg">
                    {t('cta-block.subtitle')}
                </p>

                <div className='flex flex-col sm:flex-row gap-4'>
                    <PrimaryBtn
                        className='md:text-lg sm:max-w-max'
                        onClick={() => window.requestADemo()}
                    >
                        {t('common.cta.demo')}
                    </PrimaryBtn>
                    <LearnMoreCta className="w-full" />
                </div>
            </Container>
        </section>
    )
}
