import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TranslationKey } from 'types/translation';

type P = {
    icon: string
    title: TranslationKey
    desc: TranslationKey
};

export type CardProps = P

export const Card = ({ desc, icon, title }: P) => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col gap-4 items-center text-center sm:items-start sm:text-left">
            <img
                src={icon}
                className="w-16"
                alt={t(title) as string}
            />
            <p className="text-2xl">
                {t(title) as string}
            </p>
            <p>
                {t(desc) as string}
            </p>
        </div>
    )
};
