import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Card, CardProps } from './card'
import Security from 'images/icon_security.svg'
import Privacy from 'images/icon_privacy.svg'
import Reliability from 'images/icon_reliability.svg'
import Compliance from 'images/icon_compliance.svg'

const benefits: CardProps[] = [
  {
    title: 'common.words.security',
    desc: 'security-page.benefits.benefits.1.desc',
    icon: Security,
    link: '#security'
  },
  {
    title: 'common.words.privacy',
    desc: 'security-page.benefits.benefits.2.desc',
    icon: Privacy,
    link: '#privacy'
  },
  {
    title: 'common.words.reliability',
    desc: 'security-page.benefits.benefits.3.desc',
    icon: Reliability,
    link: '#reliability'
  },
  {
    title: 'common.words.compliance',
    desc: 'security-page.benefits.benefits.4.desc',
    icon: Compliance,
    link: '#compliance'
  }
]

export const Benefits = () => {
  const { t } = useTranslation()

  return (
    <section id="use-cases" className="text-center flex flex-col gap-14">
      <div className="flex flex-col gap-6 items-center">
        <span className="tracking-wider text-gray-500 uppercase">
          {t('security-page.benefits.label')}
        </span>
        <h2 className="text-4xl tracking-tight sm:text-5xl">
          {t('security-page.benefits.title')}
        </h2>
        <p className="sm:text-lg md:text-xl max-w-2xl">
          {t('security-page.benefits.desc')}
        </p>
      </div>

      <div className="grid sm:grid-cols-4 gap-12 text-left">
        {benefits.map((benefit) => (
          <Card key={benefit.title} {...benefit} />
        ))}
      </div>
    </section>
  )
}
